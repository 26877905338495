.homepage-banner {
    // height: 203px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 60px;

    a {
        text-decoration: none;
    }

    p {
        margin: 0;
        font-family: font(main);
        font-weight: 100;
        line-height: 0.9;
        color: #FFF;
        font-size: 54px;
        padding: 35px 50px;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, .6);

        @media (max-width: $media_md) {
            font-size: 34px;
            padding: 15px 30px;
        }

        @media (max-width: $media_xs) {
            font-size: 24px;
        }

        strong {
            font-weight: 700;
        }
    }

}