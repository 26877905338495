body#homepage {
    .section {
        h1,
        .slider-title {
            font-size: 30px;
            font-weight: 500;
            text-align: center;

            @media (max-width: $media_md) {
                font-size: 24px;
            }
        }

        .section-subtitle {
            font-size: 21px;
            font-weight: 500;
            font-style: normal;
            margin-bottom: 3rem;
        }

        .section-text {
            text-align: center;
            margin-top: 50px;

            p {
                font-weight: 300;
            }
        }

        &.slider-section {
            &.slider_v3 {
                background: url('../../../images/background/services-bg.jpg') center center;

                .slider_wrapper {
                    .slider_item {
                        .item-content {
                            .item-title {
                                color: #FFF;
                            }

                            .item-desc {
                                p {
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
            }

            &.slider_v4 {
                .comment-text {
                    p {
                        font-size: 15px;
                        font-weight: 300;
                    }
                }
            }
        }

        hr.section-separator {
            margin: 30px auto;
        }
    }

    .properties-container{
        padding-bottom: 40px;
    }

	.next-slick {
        position: absolute;
        top: 41%;
        font-size: 25px;
        right: -60px;
        border: 1px solid #bfbfbf;
        margin-left: 20px;
        padding: 0 12px;
        color: #6e6e6e;
    }

    .prev-slick {
        position: absolute;
        top: 41%;
        left: -8%;
        font-size: 25px;
        border: 1px solid #bfbfbf;
        margin-left: 20px;
        padding: 0 12px;
        color: #6e6e6e;
    }

    .section-tabs {
        padding-top: 50px;
        padding-bottom: 50px;

        .content-main {
            @media(max-width: 578px) {
                padding-left: 0;
                padding-right: 0;

                .slick-slide {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}